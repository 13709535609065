import React from 'react';
import PropTypes from '../../PropTypes';

const BodyText = ({ slice }) => (
  <div dangerouslySetInnerHTML={{ __html: slice.primary.text.html }} />
);

export default BodyText;

BodyText.propTypes = {
  slice: PropTypes.object.isRequired
};
