import React from 'react';
import PropTypes from '../../PropTypes';

const BodyImage = ({ slice }) => {
  const { image } = slice.primary;

  const responsiveSizesResult = image.localFile.childImageSharp.sizes;
  const ratio = `${(1 / responsiveSizesResult.aspectRatio) * 100}%`;
  const fallbackSrc = responsiveSizesResult.src;
  const { srcSet } = responsiveSizesResult;
  const { presentationWidth } = responsiveSizesResult;

  const imageSpan = (
    <span
      style={{
        position: 'relative',
        display: 'block',
        maxWidth: `${presentationWidth}px`,
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <span
        style={{
          paddingBottom: ratio,
          position: 'relative',
          bottom: 0,
          left: 0,
          backgroundImage: `url('${responsiveSizesResult.base64}')`,
          backgroundSize: 'cover',
          display: 'block'
        }}
      >
        <img
          style={{
            width: '100%',
            height: '100%',
            margin: 0,
            verticalAlign: 'middle',
            position: 'absolute',
            top: 0,
            left: 0,
            boxShadow: 'inset 0px 0px 0px 400px'
          }}
          alt={image.alt ? image.alt : ''}
          title={image.title ? image.title : ''}
          src={fallbackSrc}
          srcSet={srcSet}
          sizes={responsiveSizesResult.sizes}
        />
      </span>
    </span>
  );

  // Make linking to original image optiotwinal.
  // 	if (true) {
  // 		rawHTML = `
  //   <a
  //     href="${originalImg}"
  //     style={display: block"
  //     target="_blank"
  //     rel="noopener"
  //   >
  //   ${rawHTML}
  //   </a>
  //     `,
  // 	}

  return <div>{imageSpan}</div>;
};
export default BodyImage;

BodyImage.propTypes = {
  slice: PropTypes.object.isRequired
};
