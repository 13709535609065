import React, { Component } from 'react';
import BodyText from './slices/BodyText';
import BodyImage from './slices/BodyImage';
import PropTypes from '../PropTypes';

export default class SliceZone extends Component {
  render() {
    const { content } = this.props;
    const result = content.map(item => {
      switch (item.slice_type) {
        case 'content':
          return <BodyText key={item.id} slice={item} />;
        case 'image':
          return <BodyImage key={item.id} slice={item} />;
        default:
          return null;
      }
    });

    return <div>{result}</div>;
  }
}

SliceZone.propTypes = {
  content: PropTypes.array.isRequired
};
