import { graphql } from 'gatsby';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import PostTags from '../components/PostTags';
import SocialLinks from '../components/SocialLinks';
import SEO from '../components/SEO';
import Card from '../components/Card';
import SliceZone from '../components/SliceZone';
import Layout from '../components/Layout';
import PropTypes from '../PropTypes';

const transformPostNode = postNode => ({
  slug: postNode.uid,
  ...postNode.data,
  title: postNode.data.title.text,
  category: postNode.data.category.document.data.name,
  tags: _.map(postNode.data.tags, x => ({
    uid: x.tag.document.uid,
    name: x.tag.document.data.name
  }))
});

const PostTemplate = props => {
  const { data, location } = props;
  const postNode = data.prismicPost;
  const posts = _.map(data.posts.edges, x => transformPostNode(x.node));
  const post = transformPostNode(postNode);


  const similarPosts = _.sortBy(
    _.filter(
      _.map(posts, x => ({
        post: x,
        score:
          x.slug !== post.slug &&
          _.intersection(_.map(x.tags, 'uid'), _.map(post.tags, 'uid')).length +
            (x.category === post.category ? 0.5 : 0)
      }))
    ),
    x => -x.score
  ).slice(0, 6);

  return (
    <Layout location={location}>
      <div className="article">
        <div
          className={classNames(
            'article_inner',
            post.slug === 'irreversible' && 'article_inner__reverse'
          )}
        >
          <SEO post={post} image={_.get(post, 'shareImage.localFile.childImageSharp.sizes.src')} />
          <Card post={post} big inPage />
          <div className="article_body">
            <SliceZone content={post.body} />
          </div>
          <div className="article_meta">
            <div className="article_tags element">
              <PostTags tags={post.tags} />
            </div>
          </div>
        </div>

        <div className="article_rip" />

        <div className="share">
          <div className="share_message element">Поделиться с ближним</div>
          <div className="share_buttons">
            <SocialLinks post={post} />
          </div>
        </div>

        {similarPosts.length ? (
          <div>
            <div className="article_similar-message element">Дальше</div>
            <div className="article_similar-list">
              {_.map(similarPosts, x => (
                <Card
                  className="element article_similar-item"
                  key={x.post.slug}
                  post={x.post}
                  size="small"
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.any.isRequired,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.location.isRequired
};

export default PostTemplate;

/* eslint no-undef: 'off' */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    prismicPost(uid: { eq: $slug }) {
      uid
      data {
        date
        title {
          text
        }
        originalTitle: original_title
        description
        category {
          document {
            ... on PrismicCategory {
              data {
                name
              }
            }
          }
        }
        cover {
          localFile {
            childImageSharp {
              sizes(maxWidth: 800, quality: 85, toFormat: JPG) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        shareImage: share_image {
          localFile {
            childImageSharp {
              sizes(maxWidth: 1200, quality: 85, toFormat: JPG) {
                ...GatsbyImageSharpSizes_noBase64
              }
            }
          }
        }
        category {
          document {
            ... on PrismicCategory {
              data {
                name
              }
            }
          }
        }
        tags {
          tag {
            document {
              ... on PrismicTag {
                uid
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyContent {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                localFile {
                  childImageSharp {
                    sizes(maxWidth: 800, quality: 85, toFormat: JPG) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 2000, sort: { fields: [last_publication_date], order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            category {
              document {
                ... on PrismicCategory {
                  data {
                    name
                  }
                }
              }
            }
            tags {
              tag {
                document {
                  ... on PrismicTag {
                    uid
                    data {
                      name
                    }
                  }
                }
              }
            }
            cover {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 800, quality: 90, toFormat: JPG) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
