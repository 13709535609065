import React from 'react';
import { Link } from 'gatsby';

import PropTypes from '../PropTypes';

const PostTags = ({ tags }) => (
  <div className="post-tags">
    {tags &&
      tags.map(tag => (
        <Link key={tag.uid} className="post-tags_element" to={`/tags/${tag.uid}`}>
          <button type="button">#{tag.name}</button>
        </Link>
      ))}
  </div>
);

PostTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({ uid: PropTypes.string.isRequired, name: PropTypes.string.isRequired })
  ).isRequired
};

export default PostTags;
